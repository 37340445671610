import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import { EComplianceStatus } from "@/constants/ComplianceStatus";

interface GetApprovedComplianceParams {
  proposalId: number;
  params: {
    status: EComplianceStatus;
  };
  shouldQueue?: boolean;
}
interface CreateApprovedComplianceParams {
  proposalId: number;
  ruleDefinitionCode: string;
  payload?: any;
}

interface RuleDefinition {
  id: number;
  code: string;
  editable: boolean;
  title: string;
}

export interface Compliance {
  approved: boolean;
  code_approver: "system" | "lender" | "backoffice";
  id: number;
  proposal_id: number;
  rule_definition: RuleDefinition;
  rule_definition_id: number;
  user_id: number;
  value: any;
}

interface GetCompliance {
  message: string;
  response: Compliance[];
  total: number;
}

export default class ComplianceResource extends AbstractOnboardResource {
  async createProposalCompliance({
    proposalId,
    ruleDefinitionCode,
    payload,
  }: CreateApprovedComplianceParams) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/rule-definitions/${ruleDefinitionCode}/compliance-logs`,
        payload,
      });

      return this.newResourceReturnFactory.create<GetCompliance>({
        response,
        data: response?.data || [],
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<GetCompliance>({
        response: e,
        data: [],
      });
    }
  }

  async sendContractsToEmail({ proposalId, email }: { proposalId: number; email: string }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/send-email`,
        payload: {
          email,
          email_category: "contracts",
        },
      });

      return this.newResourceReturnFactory.create({
        response,
        data: response?.data || [],
      });
    } catch (e) {
      return this.newResourceReturnFactory.create({
        response: e,
        data: [],
      });
    }
  }

  async getProposalCompliance({
    proposalId,
    params,
    shouldQueue = false,
  }: GetApprovedComplianceParams) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/compliance-logs`,
        params,
        shouldQueue,
      });

      return this.newResourceReturnFactory.create<GetCompliance>({
        response,
        data: response?.data || [],
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<GetCompliance>({
        response: e,
        data: [],
      });
    }
  }
}
